<template>
  <v-card class="px-6 px-md-8 pb-12 pt-8">
    <v-card-title class="justify-center text-h5 text-sm-h4 font-weight-bold">
      <div class="d-flex flex-column align-center">
        <v-btn absolute left top icon @click="handleBack">
          <v-icon v-text="'mdi-arrow-left'"/>
        </v-btn>
        <span class="bebas-neue"> Login </span>
        <v-img :src="require('@/assets/logo.png')" max-width="250px"/>
      </div>
    </v-card-title>

    <v-form v-model="valid" ref="form" autocomplete="off" class="mb-6">
      <div @keypress.enter.prevent="submit()" class="mb-4">
        <div class="label-input">E-mail</div>
        <v-text-field
          v-model="form.email"
          class="rounded-lg"
          background-color="accent"
          placeholder="Informe seu E-mail"
          :rules="emailRules"
          solo
          flat
          @keydown.space.prevent
        />

        <div class="label-input">Senha</div>

        <v-text-field
          v-model="form.password"
          class="rounded-lg"
          background-color="accent"
          :type="showPass ? 'text' : 'password'"
          placeholder="Sua senha"
          :rules="rules"
          solo
          flat
          @keydown.space.prevent
        >
          <template v-slot:append>

            <div class="d-flex align-center">
              <v-btn class="text-none py-4" text x-small @click="recoverPass()">
                <span> Recuperar senha </span>
              </v-btn>
              <v-btn icon @click="showPass = !showPass">
                <v-icon v-text="`mdi-eye${showPass ? '-off' : ''}`"/>
              </v-btn>
            </div>
          </template>
        </v-text-field>
        <v-checkbox v-model="remember" label="Lembrar-me"/>
      </div>

      <v-btn
        color="gradient"
        class="rounded-lg"
        depressed
        large
        block
        dark
        @click.prevent="submit()"
      >
        Entrar
      </v-btn>
    </v-form>

    <div class="d-flex justify-center">
      <router-link class="text-decoration-none text-center" to="/auth/sign-up">
        <span class="subtext--text subtext--text"> Não possui uma conta? </span>

        <span class="font-weight-bold primart--text"> Criar agora </span>
      </router-link>
    </div>

    <loader-hover v-if="loading"/>
  </v-card>
</template>

<script>
import {signIn} from "@/services/auth";
import {displayAlert} from "@/utils";

export default {
  data() {
    return {
      loading: false,
      valid: false,
      showPass: false,
      remember: false,
      form: {
        email: "",
        password: "",
      },
    };
  },

  watch: {
    remember(v) {
      if (!v) this.handleRemember()
    }
  },

  computed: {
    _callbackUrl: function() {
      const defaultUrl = 'https://arenajoguefacil.com.br'
      const callbackUrl = this.$route.query.callback_url
      return callbackUrl || defaultUrl
    },
    emailRules() {
      return [
        (v) => !!v || "Campo Obrigatório",
        (v) => /.+@.+\..+/.test(v) || "E-mail inválido",
      ];
    },

    rules() {
      return [(v) => !!v || "Campo Obrigatório"];
    },
  },

  beforeMount() {
    const email = localStorage.getItem('ajf_auth_email');
    if (email) {
      this.form.email = email;
      this.remember = true;
    }
  },

  methods: {
    async submit() {
      if (!this.$refs.form.validate()) return;

      try {
        this.loading = true;

        await signIn(this.form).then((res) => {
          if (this.remember) this.handleRemember()

          const {token, refreshToken} = res.body;
          this.redirect(token, refreshToken);
        });
      } catch (err) {
        this.displayAlert(err.data.message, 1);
      } finally {
        this.loading = false;
      }
    },

    redirect(token, refreshToken) {
      const callback = this.$route.query.callback_url || this.$store.getters.defaultURL;
      const url = `${callback}?tk=${token}&rtk=${refreshToken}`;

      window.open(url, '_self');
    },

    recoverPass() {

    },

    handleBack() {
      window.location.replace(this._callbackUrl);
    },

    handleRemember() {
      this.remember
        ? localStorage.setItem('ajf_auth_email', this.form.email)
        : localStorage.removeItem('ajf_auth_email');
    },

    displayAlert,
  },
};
</script>

<style>
</style>
